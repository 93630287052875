/* #footer {
  background-color: #b56357;
  width: 100%;
  padding: 5px;
} */

#footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	width: 85%;
  	background-color: #69909f;
  	margin-top: auto;
}

	#footerCopyright {
		margin-left: 10px;
		font-weight: bold;
		font-size: 20px;
    color: white;
	}

	#externalLinks {
		display: flex;
		flex-direction: column;
		margin: 10px;
	}

		#socialMediaBtns {
			display: flex;
			flex-direction: row;
			justify-content: center;
		}

		#webCondor {
			margin: auto;
			font-weight: bold;
		}

		#webCondor a {
			text-decoration: none;
			color: white;
		}
