html {
  height: 100%;
}

.sweet-loading {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

ClipLoader {
  margin: auto;
}
