.noBullets {
	list-style-type: none;
}

.contactRow {
	height: 40px;
}

 .contactIcon {
	 padding-top: 7px;
 }
