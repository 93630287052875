.textField {
  width: 300px;
  margin: 5px;
  padding: 10px;
  color: grey;
  font-family: 'Segoe UI';
  font-size: 14px;
}

.textArea {
  margin: 5px;
  padding: 10px;
  width: 302px;
  font-family: 'Segoe UI';
  font-size: 14px;
}

.fieldError {
  border: 2px solid red;
}

.textAreaError {
  margin: 5px;
  padding: 10px;
  width: 300px;
  font-family: 'Segoe UI';
  font-size: 14px;
}

.errorSubmit {
  color: red;
  margin: 10px;
}

.MuiButton-root {
  margin: 10px !important;
}
