#mainContainer {
  width: 100%;
  background-color: white;
  flex : 1; /* this makes the div take up the remainder of the height of the page, but you also have to assign min-height of 100% to .App */
  padding: 10px;
}

#mainContainer span p {
  width: 70%;
  text-align: justify;
  line-height: 23px;
}

/* Media breakpoints */
@media only screen and (max-width: 1280px) {
  #footer {
    width: 95%;
  }
}

@media only screen and (max-width: 1200px) {
  #mainContainer span p {
    width: 80%;
    line-height: 20px;
  }
}

@media only screen and (max-width: 960px) {
  #footer {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  #mainContainer span p {
    width: 90%;
    line-height: 18px;
  }
}

@media only screen and (max-width: 600px) {
  #mainContainer span p {
    width: 100%;
    line-height: 18px;
  }
}