#menuContainer {
  width: 100%;
  display: flex;
  background-color: #4896b4;
}

.active {
  color: #d4e3e9 !important;
}

.nav {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: auto;
  padding-left: 0px;
}

  .nav li {
    padding: 6px;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
  }

    .nav li a {
      position: relative;
      display: block;
      padding: 4px 0;
      font-size: 20px;
      font-weight: bold;
      color: #4f4747;
      text-decoration: none;
      text-transform: uppercase;
      transition: 0.5s;
    }

      .nav li a::after {
        position: absolute;
        content: "";
        top: 100%;
        left: 0;
        width: 100%;
        height: 3px;
        background: #d4e3e9;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.5s;
      }

      .nav li a:hover {
        color: #d4e3e9;
      }

      .nav li a:hover::after {
        transform: scaleX(1);
        transform-origin: left;
      }
